import background from "../../assets/background.svg";

export const styles = {
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(50%)",
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    maxWidth: "400px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  text1: {
    color: "present.greyBackground",
    opacity: 0.7,
    textAlign: "center",
    marginTop: "40px",
  },
  text2: {
    color: "present.greyBackground",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "25px",
  },
  text3: {
    color: "present.greyBackground",
    opacity: 0.7,
    textAlign: "center",
    marginTop: "25px",
  },
  button: {
    height: "50px",
    width: "400px",
    letterSpacing: "0",
    marginTop: "50px",
  },
};
