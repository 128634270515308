import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Rating {
  bad = "bad",
  good = "good",
}

export interface NetworkingUserDetails {
  about: string;
  commonTopics: string[];
  rating: Rating | null;
}

export type State = Array<Record<string, NetworkingUserDetails>>;

export const initialState: State = [];

const networkingRecommendationsSlice = createSlice({
  name: "networkingRecommendations",
  initialState,
  reducers: {
    setNetworkingRecommendations: (_state, action: PayloadAction<State>) =>
      action.payload,

    updateUserRating: (
      state,
      action: PayloadAction<{ userId: string; rating: Rating | null }>,
    ) => {
      const { userId, rating } = action.payload;

      // Use find to locate the user and update their rating
      const user = state.find((user) => user[userId]);
      if (user) {
        user[userId].rating = rating;
      }
    },
  },
});

export const { setNetworkingRecommendations, updateUserRating } =
  networkingRecommendationsSlice.actions;

export default networkingRecommendationsSlice.reducer;
