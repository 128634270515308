enum ActionTypes {
  HIDE_INACTIVITY_NOTICE = "app/recording/HIDE_INACTIVITY_NOTICE",
  IS_LOADING = "app/recording/IS_LOADING",
  IS_PROCESSING = "app/recording/IS_PROCESSING",
  RESET = "app/recording/RESET",
  SHOW_INACTIVITY_NOTICE = "app/recording/SHOW_INACTIVITY_NOTICE",
  START_RECORDING = "app/recording/START_RECORDING",
  STOP_RECORDING = "app/recording/STOP_RECORDING",
}

export type IBroadcastRecordingState = {
  isLoading: boolean;
  archiveId: null | string;
  isProcessing: boolean;
  isInactivityNoticeVisible: boolean;
} & (
  | {
      isRecording: true;
      recordingStartedAt: number;
    }
  | {
      isRecording: false;
      recordingStartedAt: null;
    }
);

export const initialState: IBroadcastRecordingState = {
  isLoading: false,
  isRecording: false,
  archiveId: null,
  recordingStartedAt: null,
  isProcessing: false,
  isInactivityNoticeVisible: false,
};

export default ActionTypes;
