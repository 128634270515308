import { useI18n } from "i18n";
import { useTranscriptionRequests } from "modules/transcription/hooks";
import { toggleShowCaptions } from "modules/transcription/redux";
import {
  selectShowCaptions,
  selectShowLiveCaptions,
} from "modules/transcription/redux/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import classNames from "classnames";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Switch } from "@remo-co/ui-core/src/components/Switch";
import { useMemo } from "react";
import { Transcription } from "@daily-co/daily-react";
import { TranscriptionSidebarMessage } from "../TranscriptionSidebarMessage";
import { selectTranscriptions } from "../../redux";
import useStyles from "./styles";

export const TranscriptionSidebar = () => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["transcription", "common"]);
  const { registerTranscriptionRequest, revokeTranscriptionRequest } =
    useTranscriptionRequests();
  const showCaptions = useSelector(selectShowCaptions);
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const transcriptions = useSelector(selectTranscriptions);
  const styles = useStyles();

  const processedTranscriptions = useMemo(
    () =>
      transcriptions.reduce((acc, transcript) => {
        if (transcript.is_final) {
          // @ts-expect-error type issue with @daily-co/daily-react
          if (acc.length === 0 || acc[acc.length - 1].speech_final) {
            acc.push({
              ...transcript,
              // @ts-expect-error type issue with @daily-co/daily-react
              speech_final: transcript.speech_final,
            });
          } else {
            acc[acc.length - 1].text += ` ${transcript.text}`;
            // @ts-expect-error type issue with @daily-co/daily-react
            acc[acc.length - 1].speech_final = transcript.speech_final;
          }
        }

        return acc;
      }, [] as Transcription[]),
    [transcriptions],
  );

  const clickHandler = async () => {
    if (!showLiveCaptions) {
      if (!showCaptions) {
        await registerTranscriptionRequest();
      } else {
        await revokeTranscriptionRequest();
      }
    }

    dispatch(toggleShowCaptions());
  };

  const showMessages = transcriptions.length > 0 && showCaptions;

  return (
    <div className={styles.root} data-testid="transcription-sidebar-v2">
      <div className={styles.switchContainer}>
        <Typography variant="h6">
          {t("transcription:sidebar.show.closed.captions")}
        </Typography>
        <Switch
          onClick={clickHandler}
          checked={showCaptions}
          testId="sidebar-toggle"
          darkMode
        />
      </div>
      <div
        className={classNames(styles.messagesContainer, {
          [styles.withoutMessages]: !showMessages,
        })}
      >
        {showMessages && (
          <span>
            {processedTranscriptions.map((transcript, index) => (
              <TranscriptionSidebarMessage
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                transcript={transcript}
              />
            ))}
          </span>
        )}
        {!showCaptions && (
          <Typography className={styles.captionOffText} variant="h6">
            {t("transcription:captioning.is.off.message")}
          </Typography>
        )}
      </div>
    </div>
  );
};
