import { useI18n } from "i18n";
import { ActionButton } from "modules/actionButton";
import areClosedCaptionsEnabled from "modules/companyPlanSettings/utils/areClosedCaptionsEnabled";
import { selectCurrentEventCompanyPlan } from "modules/event/selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import {
  selectIsLoading,
  selectShowCaptions,
  selectShowLiveCaptions,
} from "../../redux/selectors";
import { toggleShowLiveCaptions } from "../../redux/transcriptionSlice";
import { useTranscriptionRequests } from "../../hooks/useTranscriptionRequests";
import { ReactComponent as ClosedCaptionIcon } from "./icon.inline.svg";

interface Props {
  darkMode?: boolean;
}

export const ClosedCaptionButton = ({
  darkMode,
}: Props): JSX.Element | null => {
  const { t } = useI18n(["transcription"]);
  const dispatch = useAppDispatch();
  const showLiveCaptions = useSelector(selectShowLiveCaptions);
  const showCaptions = useSelector(selectShowCaptions);
  const { registerTranscriptionRequest, revokeTranscriptionRequest } =
    useTranscriptionRequests();
  const eventCompanyPlan = useSelector(selectCurrentEventCompanyPlan);
  const isLoading = useSelector(selectIsLoading);

  if (!areClosedCaptionsEnabled(eventCompanyPlan)) {
    return null;
  }

  const handleClosedCaptionButtonClick = async () => {
    if (!showCaptions) {
      if (!showLiveCaptions) {
        await registerTranscriptionRequest();
      } else {
        await revokeTranscriptionRequest();
      }
    }

    dispatch(toggleShowLiveCaptions());
  };

  const titleText = t("transcription:button.closed.caption.title");
  const labelText = showLiveCaptions
    ? t("transcription:button.closed.caption.stop")
    : t("transcription:button.closed.caption.start");

  return (
    <ActionButton
      title={titleText}
      onClick={handleClosedCaptionButtonClick}
      label={labelText}
      isActive={showLiveCaptions}
      loading={showLiveCaptions && isLoading}
      throttleTime={1000}
      icon={<ClosedCaptionIcon />}
      darkMode={darkMode}
      data-testid="closed-caption-button"
    />
  );
};
