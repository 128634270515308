import { Transcription } from "@daily-co/daily-react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITranscriptionReducerState {
  isLoading: boolean;
  showCaptions: boolean;
  showLiveCaptions: boolean;
  isActive: boolean;
  transcriptions: Transcription[];
}

export const transcriptionInitialState: ITranscriptionReducerState = {
  isLoading: false,
  showCaptions: false,
  showLiveCaptions: false,
  isActive: false,
  transcriptions: [],
};

export const transcriptionSlice = createSlice({
  name: "transcription",
  initialState: transcriptionInitialState,
  reducers: {
    startTranscribing: (state) => ({
      ...state,
      isActive: true,
    }),
    stopTranscribing: () => ({
      ...transcriptionInitialState,
      transcriptions: [], // reset transcriptions on store
      isActive: false,
    }),
    toggleShowCaptions: (state) => {
      state.showCaptions = !state.showCaptions;
    },
    toggleShowLiveCaptions: (state) => {
      state.showLiveCaptions = !state.showLiveCaptions;
    },
    hideCaptions: (state) => {
      state.showCaptions = false;
    },
    hideLiveCaptions: (state) => {
      state.showLiveCaptions = false;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTranscriptions: (state, action) => ({
      ...state,
      transcriptions: action.payload,
    }),
    reset: () => transcriptionInitialState,
  },
});

export const {
  startTranscribing,
  stopTranscribing,
  setTranscriptions,
  toggleShowCaptions,
  toggleShowLiveCaptions,
  hideCaptions,
  hideLiveCaptions,
  setIsLoading,
  reset,
} = transcriptionSlice.actions;

export default transcriptionSlice.reducer;
