import { Transcription } from "@daily-co/daily-react";
import { usePrevious } from "helpers/reactHooksUtils";
import useDebounce from "modules/common/hooks/useDebounce";
import throttle from "modules/throttle";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTranscriptions } from "../../redux";

type UserTranscript = {
  userId: string;
  data: Transcription;
  time: number;
};

interface TranscriptionFeed {
  transcripts: UserTranscript[];
  activeSpeaker: string | null;
}

export const NEW_MESSAGE_THROTTLE_TIME = 100;
export const TRANSCRIPT_OLD_TIME = 3000;
export const TRANSCRIPT_REMOVAL_INTERVAL = 1000;
export const ACTIVE_SPEAKER_DEBOUNCE_TIME = 500;

export const useTranscriptionFeed = (): TranscriptionFeed => {
  const transcripts = useSelector(selectTranscriptions);
  const latestTranscript = transcripts.length
    ? transcripts[transcripts.length - 1]
    : null;
  const previousTranscript = usePrevious(latestTranscript);

  const userId = latestTranscript?.user_id;

  const [activeSpeaker, setActiveSpeaker] = useState<string | null>(null);

  const debouncedActiveSpeaker = useDebounce(
    activeSpeaker,
    ACTIVE_SPEAKER_DEBOUNCE_TIME,
  );

  const [throttledTranscripts, setThrottledTranscripts] = useState<
    UserTranscript[]
  >([]);

  const isOld = (ts: UserTranscript) =>
    ts.time <= Date.now() - TRANSCRIPT_OLD_TIME;

  const throttledSet = useMemo(
    () => throttle(setThrottledTranscripts, NEW_MESSAGE_THROTTLE_TIME),
    [setThrottledTranscripts],
  );

  useEffect(() => {
    if (
      transcripts &&
      userId &&
      previousTranscript &&
      latestTranscript.text !== previousTranscript.text
    ) {
      throttledSet((prev) => {
        const newTranscripts = prev.filter((ts) => {
          const isDuplicate = ts.userId === userId;
          return !isDuplicate && !isOld(ts);
        });
        const currentTime = Date.now();
        newTranscripts.push({
          userId,
          data: latestTranscript,
          time: currentTime,
        });

        return newTranscripts;
      });
      setActiveSpeaker(userId);
    }
  }, [transcripts, previousTranscript, userId, throttledSet, latestTranscript]);

  useEffect(() => {
    const removeOldTranscripts = (transcripts: UserTranscript[]) =>
      transcripts.filter((ts) => !isOld(ts));

    const interval = setInterval(() => {
      const newTranscripts = removeOldTranscripts(throttledTranscripts);
      throttledSet(newTranscripts);
    }, TRANSCRIPT_REMOVAL_INTERVAL);
    return () => clearInterval(interval);
  }, [throttledTranscripts, throttledSet]);

  return {
    transcripts: throttledTranscripts,
    activeSpeaker: debouncedActiveSpeaker,
  };
};
