import { useMutation } from "@tanstack/react-query";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { useContext } from "react";
import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { Errors, trackError } from "modules/monitoring";
import { addErrorNotification } from "modules/notification/redux/notificationSlice";
import { useI18n } from "i18n";
import {
  addRecommendedUserRating,
  AddRecommendedUserRatingPayload,
} from "../../request";
import { updateUserRating } from "../../redux/slice";

export const useAddRecommendedUserRating = (userId?: string) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const dispatch = useAppDispatch();
  const currentUser = useSelector(selectUser);
  const { t } = useI18n(["event"]);
  const userDetails = useSelector(makeSelectUserById(userId));
  const currentEventId = useSelector(selectCurrentEventId);

  const { mutate: addRating } = useMutation({
    mutationFn: (payload: AddRecommendedUserRatingPayload) =>
      addRecommendedUserRating(payload),
    onSuccess: (data) => {
      if (data?.data && data?.data?.rating !== undefined) {
        track(Events.RECOMMENDED_CONNECTION_RATED, {
          eventId: currentEventId,
          rating: data.data.rating,
          recommendedUser: userDetails?.email,
          currentUser: currentUser?.email,
        });
        dispatch(
          updateUserRating({
            userId: data.data.recommendedUser,
            rating: data.data.rating,
          }),
        );
      }
    },
    onError: (error: Error, _) => {
      dispatch(
        addErrorNotification({
          message: t("add.recommended.user.rating.error"),
          position: "tc",
        }),
      );
      trackError(error, {
        label: Errors.ADD_RECOMMENDED_USER_RATING,
      });
    },
  });
  return { addRating };
};
