import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectBroadcastRecording = (state: RootState) => state.broadcastRecording;

const selectIsProcessing = createSelector(
  selectBroadcastRecording,
  (substate) => substate.isProcessing,
);

const selectIsLoading = createSelector(
  selectBroadcastRecording,
  (substate) => substate.isLoading,
);

const selectIsInactivityNoticeVisible = createSelector(
  selectBroadcastRecording,
  (substate) => substate.isInactivityNoticeVisible,
);

export {
  selectBroadcastRecording,
  selectIsProcessing,
  selectIsLoading,
  selectIsInactivityNoticeVisible,
};
