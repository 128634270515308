import { useDrop } from "react-dnd";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { TableWithId, DragItem } from "../../types";
import {
  AttendeeTableUpdatePayload,
  AttendeeTableMovePayload,
} from "../../utils";
import { DRAGGABLE_ATTENDEE_TYPE } from "../../constants";
import { UserRow } from "../UserRow";
import { TableItemBlankState } from "../TableItemBlankState";
import { TableActionsButton } from "../TableActionsButton";
import useStyles from "./styles";
import { TableInfoPopover } from "../TableInfoPopover";

interface Props {
  table: TableWithId;
  floorId: string;
  eventId: string;
  hasMultipleFloors: boolean;
  selectedPreset: string;
  setHoveredTableCode: (tableId: string | null) => void;
  addAttendeeToTable: (payload: AttendeeTableUpdatePayload) => void;
  moveAttendeeToTable: (payload: AttendeeTableMovePayload) => void;
  unassignAttendeesFromTable: (floorId: string, tableId: string) => void;
  setHoveredUserId?: (id: string | null) => void;
}

const TableItem = ({
  table,
  floorId,
  eventId,
  hasMultipleFloors,
  selectedPreset,
  setHoveredTableCode,
  addAttendeeToTable,
  moveAttendeeToTable,
  unassignAttendeesFromTable,
  setHoveredUserId,
}: Props): JSX.Element => {
  const styles = useStyles();
  const { t } = useI18n(["common", "manageEvent"]);
  const { addErrorNotification } = useNotificationActions();
  const users = table.users ?? [];

  const [_, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_ATTENDEE_TYPE,
      drop: (item: DragItem) => {
        if (users.length >= table.limit) {
          addErrorNotification({
            message: t("manageEvent:error.table.full"),
            position: "tc",
          });

          return;
        }

        if (!item.tableId || !item.floorId) {
          // attendee came from unassigned attendee list
          addAttendeeToTable({
            attendee: item.user,
            floorId,
            tableId: table.id,
          });

          return;
        }

        // dispatch only if dragged to different table
        if (item.tableId !== table.id) {
          moveAttendeeToTable({
            attendee: item.user,
            floorId,
            tableId: table.id,
            previousTableId: item.tableId,
          });
        }
      },
    }),
    [table, floorId, moveAttendeeToTable, addAttendeeToTable],
  );
  const shownTableName = table.name.length ? table.name : t("table.unnamed");

  return (
    <div
      ref={drop}
      className={styles.container}
      onFocus={() => setHoveredTableCode(table.code)}
      onDragOver={() => setHoveredTableCode(table.code)}
      onMouseOver={() => setHoveredTableCode(table.code)}
      onBlur={() => setHoveredTableCode(null)}
      onDragLeave={() => setHoveredTableCode(null)}
      onMouseLeave={() => setHoveredTableCode(null)}
      data-testid={`drop-container-${table.id}`}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        className={styles.header}
      >
        <div>
          <Container flex alignItems="center">
            <Typography variant="h5" className={styles.title}>
              {shownTableName}
            </Typography>
            {table.users?.length && selectedPreset === "default" ? (
              <TableInfoPopover />
            ) : null}
          </Container>
          <Typography variant="body2" className={styles.title}>
            {users.length}/{table.limit}
          </Typography>
        </div>
        <TableActionsButton
          eventId={eventId}
          table={table}
          hasMultipleFloors={hasMultipleFloors}
          selectedPreset={selectedPreset}
          unassignTableAttendees={() =>
            unassignAttendeesFromTable(floorId, table.id)
          }
        />
      </Box>
      <Container className={styles.content}>
        {!users.length ? <TableItemBlankState /> : null}
        <Container className={styles.rows}>
          {users.map((user) => (
            <UserRow
              key={user.email}
              user={user}
              floorId={floorId}
              tableId={table.id}
              setHoveredUserId={setHoveredUserId}
            />
          ))}
        </Container>
      </Container>
    </div>
  );
};

export default TableItem;
