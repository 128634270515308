import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
  header: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
  },
  actions: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    justifyContent: "space-between",
  },
  contentTypeLabel: {
    marginTop: theme.spacing(2.5),
  },
}));
