import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectConversationBroadcastSessionId } from "modules/conversationBroadcast/redux/selectors";
import { useAppDispatch } from "store/hooks";
import { openSendDialog } from "modules/announcement/redux/announcementSlice";
import { ReactComponent as AnnouncementIcon } from "./icons/announcement-icon.inline.svg";

const AnnouncementButton = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const conversationBroadcastSessionId = useSelector(
    selectConversationBroadcastSessionId,
  );

  const openAnnouncementSendDialog = () => {
    dispatch(openSendDialog());
  };

  return (
    <ActionButton
      title={t("button.announcement")}
      label={t("button.announcement")}
      onClick={openAnnouncementSendDialog}
      isPopoverButton
      icon={<AnnouncementIcon />}
      disabled={!!conversationBroadcastSessionId}
      tooltipPlacement="left"
    />
  );
};

export default AnnouncementButton;
