import { useCallback, useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectUser } from "modules/auth/redux/selectors";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { camelCase } from "lodash";
import { useSelector } from "react-redux";
import { selectCurrentEvent } from "modules/event/selectors";
import { Actions, trackAction } from "modules/monitoring";
import { EventTrackingStateContext } from "../../context/eventTrackingContext";
import { checkEventExists } from "../../eventTrackingHelper";
import { useEventTrackingReducerActions } from "../../context/eventTrackingReducer";

const useEventTracking = () => {
  const currentEvent = useSelector(selectCurrentEvent);
  const user = useSelector(selectUser);
  const { history } = useContext(EventTrackingStateContext);
  const actions = useEventTrackingReducerActions();
  const { serverTime } = useSystemClock();
  const { track } = useContext(TRACKING_CONTEXT);

  const addTrackingLog = useCallback((event: string, data?: unknown) => {
    actions.addTrackingLog({ date: serverTime, event, data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackEventAttend = useCallback(
    () => {
      const isTracked = checkEventExists(history, "AttendEvent");

      if (!isTracked && currentEvent && user) {
        addTrackingLog("AttendEvent");
      }

      if (currentEvent && user) {
        trackAction(Actions.EVENT_ENTERED, {
          [camelCase(Actions.EVENT_ENTERED)]: currentEvent,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, currentEvent, user],
  );

  const trackSwitchTables = useCallback(
    (table: string) => {
      if (currentEvent && user) {
        track(Events.MOVED_TABLES, {
          event: currentEvent.id,
          table,
        });

        trackAction(Actions.MOVED_TABLES, {
          eventId: currentEvent.id,
        });
      }
    },
    [currentEvent, user, track],
  );

  const trackSwitchFloors = useCallback(
    (floor: string) => {
      if (currentEvent && user) {
        track(Events.MOVED_FLOORS, {
          event: currentEvent.id,
          floor,
        });

        trackAction(Actions.MOVED_FLOORS, {
          eventId: currentEvent.id,
        });
      }
    },
    [currentEvent, user, track],
  );

  const trackSwitch = useCallback(
    (roomId: string | null, spaceId: string | null) => {
      if (spaceId) {
        trackSwitchFloors(spaceId);

        return;
      }

      if (roomId) {
        trackSwitchTables(roomId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const trackPresentationStart = useCallback(() => {
    if (currentEvent && user) {
      track(Events.PRESENTATION_START, {
        event: currentEvent.id, // remove after complete migration to datagol
        eventId: currentEvent.id,
        userId: user.id,
      });
    }
  }, [currentEvent, user, track]);

  const trackPresentationStop = useCallback(() => {
    if (currentEvent && user) {
      track(Events.PRESENTATION_STOP, {
        event: currentEvent.id, // remove after complete migration to datagol
        eventId: currentEvent.id,
        userId: user.id,
      });
    }
  }, [currentEvent, user, track]);

  const trackSponsorBannerClick = useCallback(
    (sponsor: string) => {
      if (currentEvent && user) {
        track(Events.SPONSOR_BANNER_CLICKED, {
          event: currentEvent.id,
          userId: user.id,
          sponsor,
        });
      }
    },
    [currentEvent, user, track],
  );

  const trackGetAttendeeOnStage = useCallback(() => {
    if (currentEvent && user) {
      track(Events.GET_ATTENDEE_ON_STAGE, {
        eventId: currentEvent.id,
      });

      trackAction(Actions.PRESENTATION_MODE_GET_ATTENDEE_ON_STAGE, {
        eventId: currentEvent.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent, user]);

  const trackPublicChatMessage = useCallback(() => {
    if (currentEvent && user) {
      track(Events.PUBLIC_CHAT_MESSAGE_SENT, {
        event: currentEvent.id, // remove after complete migration to datagol
        eventId: currentEvent.id,
      });
    }
  }, [currentEvent, user, track]);

  const reset = useCallback(() => {
    actions.reset();
  }, [actions]);

  return {
    trackEventAttend,
    trackSwitch,
    trackPresentationStart,
    trackPresentationStop,
    trackSponsorBannerClick,
    trackGetAttendeeOnStage,
    trackPublicChatMessage,
    reset,
  };
};

export default useEventTracking;
