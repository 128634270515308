import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { useSelector } from "react-redux";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { TABS, selectRightPanelState, setPanelState } from "modules/rightPanel";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { ParticipantsIcon } from "@remo-co/ui-core/src/icons/Participants";
import { useState, useCallback } from "react";
import { selectRecommendedUsersWithNoRating } from "modules/networkingRecommendations/redux/selectors";
import { useStyles } from "./styles";
import { ConnectionsToolTip } from "./components/ConnectionsTooltip";

interface Props {
  isSmallButton?: boolean;
  darkMode?: boolean;
}

const ConnectionsButton = ({ isSmallButton, darkMode }: Props): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const { t } = useI18n(["event"]);
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { isOpen, tabId } = useSelector(selectRightPanelState);
  const networkingConnectionsWithNoRating = useSelector(
    selectRecommendedUsersWithNoRating,
  );

  const handleClick = useCallback(() => {
    const newTabState = {
      isOpen: !isOpen || (isOpen && tabId !== TABS.NETWORKING_RECOMMENDATIONS),
      tabId: TABS.NETWORKING_RECOMMENDATIONS,
    };

    dispatch(setPanelState(newTabState));

    if (isOpen) {
      dispatch(updateActiveChannel(null));
    }
  }, [isOpen, tabId, dispatch]);

  const isButtonActive = isOpen && tabId === TABS.NETWORKING_RECOMMENDATIONS;

  return (
    <ActionButton
      data-testid="connections-button"
      onClick={handleClick}
      label={t("event:connections")}
      isActive={isButtonActive}
      isPopoverButton={isSmallButton}
      darkMode={darkMode}
      showTooltip={false}
      icon={
        <Tooltip
          open={tooltipOpen}
          title={
            <ConnectionsToolTip
              setTooltipOpen={setTooltipOpen}
              handleClick={handleClick}
            />
          }
          arrow
          placement="bottom"
          disableHoverListener={false}
          classes={{ tooltip: styles.tooltip }}
        >
          <Badge
            badgeContent={networkingConnectionsWithNoRating.length}
            color="error"
          >
            <ParticipantsIcon />
          </Badge>
        </Tooltip>
      }
    />
  );
};

export default ConnectionsButton;
