import { memo, useCallback, useMemo } from "react";
import { useI18n } from "i18n";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemAvatar } from "@remo-co/ui-core/src/components/ListItemAvatar";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { ThumbUpIcon } from "@remo-co/ui-core/src/icons/ThumbsUp";
import { ThumbDownIcon } from "@remo-co/ui-core/src/icons/ThumbsDown";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import Avatar from "modules/userAvatar/Avatar";
import { getUserName } from "modules/userProfile";
import { IUser } from "modules/app/types";
import { useSelector } from "react-redux";
import {
  selectCurrentEventId,
  selectCurrentMatchPoolId,
} from "modules/event/selectors";
import { useAddRecommendedUserRating } from "modules/networkingRecommendations/hooks/useAddRecommendedUserRating";
import { Rating } from "../../redux/slice";
import { useStyles } from "./styles";

interface Props {
  user?: IUser;
  rating?: Rating | null;
}

const NetworkingUserRow = ({ user, rating }: Props) => {
  const currentEventId = useSelector(selectCurrentEventId);
  const currentMatchPoolId = useSelector(selectCurrentMatchPoolId);
  const styles = useStyles();
  const { t } = useI18n("event");
  const { addRating } = useAddRecommendedUserRating(user?.id);

  const handleRating = useCallback(
    (newRating: Rating | null) => {
      if (user && currentEventId && currentMatchPoolId) {
        addRating({
          eventId: currentEventId,
          rating: newRating,
          recommendedUser: user.id,
        });
      }
    },
    [addRating, currentEventId, currentMatchPoolId, user],
  );

  const { id, profile } = user ?? {};
  const name = getUserName(user);
  const picture = profile?.picture;
  const ratingIcons = useMemo(() => {
    if (rating === Rating.good) {
      return (
        <ThumbUpIcon
          className={styles.greenThumbsUpIcon}
          onClick={() => handleRating(null)}
          data-testid={`thumbs-up-icon-${rating}`}
        />
      );
    }

    if (rating === Rating.bad) {
      return (
        <ThumbDownIcon
          className={styles.redThumbsDownIcon}
          onClick={() => handleRating(null)}
          data-testid={`thumbs-down-icon-${rating}`}
        />
      );
    }

    return (
      <>
        <Tooltip title={t("thumbs.up.tooltip")}>
          <ThumbUpIcon
            className={styles.greenThumbsUpIcon}
            onClick={() => handleRating(Rating.good)}
            data-testid="good-rating-networking-recommendations"
          />
        </Tooltip>
        <Tooltip title={t("thumbs.down.tooltip")}>
          <ThumbDownIcon
            className={styles.redThumbsDownIcon}
            onClick={() => handleRating(Rating.bad)}
            data-testid="bad-rating-networking-recommendations"
          />
        </Tooltip>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleRating,
    rating,
    styles.greenThumbsUpIcon,
    styles.redThumbsDownIcon,
  ]);

  if (!user) return null;

  return (
    <div
      className={styles.userNetworkingRowContainer}
      data-testid={`networking-user-row-${id}`}
    >
      <ListItem>
        {picture && (
          <ListItemAvatar>
            <Avatar
              data-testid="networking-user-row-avatar"
              className={styles.bcuAvatar}
              user={user}
            />
          </ListItemAvatar>
        )}
        <div className={styles.networkingUserDetails}>
          <ListItemText
            className={styles.userName}
            primary={name || "No name"}
          />
          {profile?.title && (
            <Typography variant="caption">{profile.title}</Typography>
          )}
        </div>
        <div className={styles.ratingIconsBox}>
          {rating ? (
            <>
              <Typography variant="caption">{t("rated")}</Typography>
              {ratingIcons}
            </>
          ) : (
            ratingIcons
          )}
        </div>
      </ListItem>
    </div>
  );
};

export default memo(NetworkingUserRow);
