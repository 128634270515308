import { makeSelectUserById } from "modules/event/usersInEvent/selectors";
import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useMemo, useState } from "react";
import { useI18n } from "i18n";
import { NetworkingUserDetails } from "../../redux/slice";
import { NetworkingUserRow } from "../NetworkingUserRow";
import { DiscussionContent } from "../DiscussionContent";
import { NetworkingButtons } from "../NetworkingButtons";
import { useStyles } from "./styles";

interface Props {
  user: Record<string, NetworkingUserDetails>;
}

export const NetworkingParticipantDetails = ({ user }: Props) => {
  const styles = useStyles();
  const { t } = useI18n("event");
  const [showDetails, setShowDetails] = useState(false);

  // Select user ID and details
  const userId = useMemo(() => Object.keys(user)[0], [user]);
  const userInfo = user[userId];
  const userDetails = useSelector(makeSelectUserById(userId));

  return (
    <div
      className={styles.userDetailsContainer}
      data-testid="networking-participant-details"
    >
      <NetworkingUserRow user={userDetails} rating={userInfo?.rating} />

      {(!userInfo?.rating || showDetails) && (
        <>
          <div>
            <Typography variant="body1" className={styles.textBold}>
              {t("about")}
            </Typography>
            <Typography variant="body1">{userInfo?.about}</Typography>
          </div>

          {userInfo?.commonTopics?.length > 0 && (
            <div>
              <Typography variant="body1" className={styles.commonTopicHeading}>
                {t("both.of.you.can.talk.about")}
              </Typography>
              <DiscussionContent topics={userInfo.commonTopics} />
            </div>
          )}

          {userDetails && <NetworkingButtons user={userDetails} />}
        </>
      )}

      {!showDetails && (
        <Typography
          variant="body1"
          onClick={() => setShowDetails(true)}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {t("expand.connection.details")}
        </Typography>
      )}
    </div>
  );
};
