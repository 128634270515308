import React from "react";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import {
  selectIsTimerRunning,
  selectTimerSetupActive,
} from "modules/timer/redux/selectors";
import { useAppDispatch } from "store/hooks";
import { toggleTimerSetupActive } from "modules/timer/redux/slice";
import { ActionButton } from "modules/actionButton";
import { ReactComponent as TimerButtonIcon } from "./icons/timer-icon.inline.svg";

const TimerButton = ({ darkMode = false }): JSX.Element => {
  const isTimerRunning = useSelector(selectIsTimerRunning);
  const setupVisible = useSelector(selectTimerSetupActive);
  const dispatch = useAppDispatch();

  const { t } = useI18n();

  const toggleTimerSetup = React.useCallback(() => {
    dispatch(toggleTimerSetupActive());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = t("open.timer.panel");

  if (setupVisible && isTimerRunning) {
    title = t("need.stop.timer");
  } else if (setupVisible) {
    title = t("close.timer.panel");
  }

  return (
    <ActionButton
      title={title}
      label={t("button.timer")}
      onClick={toggleTimerSetup}
      isActive={setupVisible}
      disabled={isTimerRunning}
      isPopoverButton
      icon={<TimerButtonIcon />}
      darkMode={darkMode}
      tooltipPlacement="left"
    />
  );
};

export default TimerButton;
