import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Rating } from "modules/networkingRecommendations/redux/slice";
import { ThumbDownIcon } from "@remo-co/ui-core/src/icons/ThumbsDown";
import { ThumbUpIcon } from "@remo-co/ui-core/src/icons/ThumbsUp";
import { useI18n } from "i18n";
import {
  selectCurrentEventId,
  selectCurrentMatchPoolId,
} from "modules/event/selectors";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useAddRecommendedUserRating } from "modules/networkingRecommendations/hooks/useAddRecommendedUserRating";
import { selectNetworkingRecommendationUserRating } from "modules/networkingRecommendations/redux/selectors";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

interface Props {
  userId: string;
}

export const RatingIcons = ({ userId }: Props) => {
  const rating = useSelector(selectNetworkingRecommendationUserRating(userId));
  const { t } = useI18n("event");
  const styles = useStyles();
  const currentEventId = useSelector(selectCurrentEventId);
  const currentMatchPoolId = useSelector(selectCurrentMatchPoolId);

  const { addRating } = useAddRecommendedUserRating(userId);

  const handleRating = (newRating: Rating | null) => {
    if (userId && currentEventId && currentMatchPoolId) {
      addRating({
        eventId: currentEventId,
        rating: newRating,
        recommendedUser: userId,
      });
    }
  };

  return (
    <div
      className={styles.networkingRatingContainer}
      data-testid="rating-icons-container"
    >
      {!rating ? (
        <>
          <Typography variant="caption">{t("rate.connection")}</Typography>
          <Tooltip title={t("thumbs.up.tooltip")}>
            <ThumbUpIcon
              className={styles.greenThumbsUpIcon}
              onClick={() => handleRating(Rating.good)}
              data-testid="network-rating-good"
            />
          </Tooltip>
          <Tooltip title={t("thumbs.down.tooltip")}>
            <ThumbDownIcon
              className={styles.redThumbsDownIcon}
              onClick={() => handleRating(Rating.bad)}
              data-testid="network-rating-bad"
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Typography variant="caption">{t("rated")}</Typography>
          {rating === Rating.good ? (
            <ThumbUpIcon
              className={styles.greenThumbsUpIcon}
              data-testid="networking-rating-good"
              onClick={() => handleRating(null)}
            />
          ) : (
            <ThumbDownIcon
              className={styles.redThumbsDownIcon}
              data-testid="networking-rating-bad"
              onClick={() => handleRating(null)}
            />
          )}
        </>
      )}
    </div>
  );
};
