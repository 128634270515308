import ActionTypes from "./constants";

const startRecordingBroadcast = (
  archiveId: string,
  recordingStartedAt: number,
) => ({
  payload: { archiveId, recordingStartedAt },
  type: ActionTypes.START_RECORDING as const,
});

const stopRecordingBroadcast = () => ({
  type: ActionTypes.STOP_RECORDING as const,
});

const reset = () => ({
  type: ActionTypes.RESET as const,
});

const isProcessing = (isCurrentlyProcessing: boolean) => ({
  type: ActionTypes.IS_PROCESSING as const,
  payload: { isProcessing: isCurrentlyProcessing },
});

const setIsLoading = (isLoading: boolean) => ({
  type: ActionTypes.IS_LOADING as const,
  payload: { isLoading },
});

export const showInactivityNotice = () => ({
  type: ActionTypes.SHOW_INACTIVITY_NOTICE as const,
});

export const hideInactivityNotice = () => ({
  type: ActionTypes.HIDE_INACTIVITY_NOTICE as const,
});

export type ReducerActions =
  | ReturnType<typeof startRecordingBroadcast>
  | ReturnType<typeof stopRecordingBroadcast>
  | ReturnType<typeof reset>
  | ReturnType<typeof isProcessing>
  | ReturnType<typeof showInactivityNotice>
  | ReturnType<typeof hideInactivityNotice>
  | ReturnType<typeof setIsLoading>;

export default {
  startRecordingBroadcast,
  stopRecordingBroadcast,
  reset,
  isProcessing,
  setIsLoading,
};
