import { SearchContextManager } from "@giphy/react-components";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { IChatMessage } from "modules/chat/types";
import { useChatActions } from "modules/chat/hooks";
import { makeSelectActiveChannel } from "modules/chat/redux/selectors";
import { CHAT_MESSAGE_TYPES } from "modules/chat/redux/constants";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { GIPHY_SDK_KEY } from "./getGiphySDKKey";
import { GiphyGrid } from "./GiphyGrid";
import useStyles from "./styles";
import { GifID } from "../messagesView/types/GifMessage/types";

interface Props {
  hideGifContainer: () => void;
}

export const GiphyContainer = ({ hideGifContainer }: Props) => {
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const styles = useStyles({ isBroadcasting: Boolean(isBroadcasting) });
  const { sendMessage } = useChatActions();
  const channel = useSelector(makeSelectActiveChannel);

  const handleGifSend = useCallback(
    (gifKey: GifID) => {
      sendMessage(channel, {
        type: CHAT_MESSAGE_TYPES.GIF,
        gifKey,
      } as unknown as IChatMessage);

      hideGifContainer();
    },
    [channel, hideGifContainer, sendMessage],
  );

  return (
    <SearchContextManager apiKey={GIPHY_SDK_KEY}>
      <Container
        data-testid="giphy-container"
        className={styles.giphyContainer}
      >
        <GiphyGrid onGifSelect={handleGifSend} />
      </Container>
    </SearchContextManager>
  );
};
