import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsMeBroadcaster } from "modules/broadcaster";
import {
  selectIsEventManager,
  selectIsEventSpeaker,
} from "modules/event/selectors";
import { selectIsInBroadcast } from "modules/broadcast/redux/selectors";
import { useTranscriptionRequests } from "../../hooks/useTranscriptionRequests";

export const TranscriptionSubscriber = () => {
  const {
    subscribe: subscribeToRequests,
    unsubscribe: unsubscribeFromRequests,
  } = useTranscriptionRequests();
  const isInBroadcast = useSelector(selectIsInBroadcast);

  const isMeBroadcaster = useSelector(selectIsMeBroadcaster);
  const isEventSpeaker = useSelector(selectIsEventSpeaker);
  const isEventManager = useSelector(selectIsEventManager);
  const isSpeaker = isMeBroadcaster || isEventSpeaker || isEventManager;

  useEffect(() => {
    if (isInBroadcast && isSpeaker) {
      subscribeToRequests();

      return unsubscribeFromRequests;
    }

    unsubscribeFromRequests();

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInBroadcast, isSpeaker]);

  return null;
};
