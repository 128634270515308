import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Radio } from "@remo-co/ui-core/src/components/Radio";
import { RadioGroup } from "@remo-co/ui-core/src/components/RadioGroup";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import "./CustomizeTheme.scss";
import { MANAGE_EVENT_CONTEXT, useManageEvent } from "modules/manageEvent";
import { DEFAULT_THEME, FLOOR_PLAN_THEMES } from "modules/customFloorPlan";
import { useCompanyPlanSettings } from "modules/companyPlanSettings";
import { isInPostTrialPlan } from "modules/companyPlanSettings/utils/trial";
import { CustomFloorPlanUpload } from "modules/customFloorPlan/settings/CustomFloorPlanUpload";
import UploaderDialog from "modules/eventForm/customizeTheme/UploaderDialog";
import { isTypeOfIMapTemplate } from "modules/event/template";
import { isClassicTheme } from "modules/eventForm/utils";
import { ManageEventState } from "modules/manageEvent/hooks/types";
import { ITheater, IMapTemplate } from "types/theater";
import { FloorPlanSelectTags } from "modules/customFloorPlan/components/FloorPlanSelectTags";
import { EventThemeSelector } from "../EventThemeSelector";
import { FloorPlanLayout } from "../FloorPlanLayout";
import { EventFloorSettings } from "../EventFloorSettings";
import { useStyles } from "./styles";

enum TEMPLATE_TYPES {
  CUSTOM = "CUSTOM",
  REMO = "REMO",
}

const CustomizeTheme = (): JSX.Element => {
  const {
    state,
    isExpectedAttendanceDisabled,
    templates = undefined,
    updatePartialEventData,
  } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData = null } = state as Partial<ManageEventState>;
  const { onLayoutSelect: onSelect } = useManageEvent();
  const { myCompanyPlan } = useCompanyPlanSettings();
  const { t } = useI18n(["common", "eventForm", "customFloorPlan"]);
  const { isUnlimitedEvent } = eventData ?? {};
  const [planType, setPlanType] = useState<string>(TEMPLATE_TYPES.REMO);
  const [uploaderDialogOpen, setUploaderDialogOpen] = useState(false);
  const [_, setShowSeatWarning] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const theaterData =
    (eventData?.theaters && eventData?.theaters[0]) || ({} as ITheater);
  const currentTheme = theaterData.theme;
  const [displayedTheme, setDisplayedTheme] = useState(
    currentTheme ?? DEFAULT_THEME,
  );
  const [floorCount, setFloorCount] = useState(1);
  const styles = useStyles();

  useEffect(() => {
    if (!theaterData?.id) return;
    // Show the selected tab based on the template
    if (isTypeOfIMapTemplate(theaterData?.template)) {
      setPlanType(
        theaterData.template.isPrivate
          ? TEMPLATE_TYPES.CUSTOM
          : TEMPLATE_TYPES.REMO,
      );
    }
  }, [theaterData]);

  const handleUploaderDialogClose = () => {
    setUploaderDialogOpen(false);
  };

  const handleTheaterChange = (theater: ITheater) => {
    updatePartialEventData({ theaters: [{ ...theaterData, ...theater }] });
  };

  const onUserSelectLayout = (template: IMapTemplate, theme?: string) => {
    onSelect(template, theme, eventData, theaterData, updatePartialEventData);
  };

  const onTypeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    setPlanType(e.target.value);
  };

  const handleFloorCount = (count: number) => {
    setFloorCount(count);
  };

  const filteredPublicTemplates = useMemo(() => {
    if (templates && planType === TEMPLATE_TYPES.REMO) {
      return templates.publicTemplates.filter((template) =>
        isClassicTheme(displayedTheme)
          ? template.floorPlanType === FLOOR_PLAN_THEMES.CLASSIC
          : displayedTheme === template.floorPlanType,
      );
    }

    return null;
  }, [displayedTheme, templates, planType]);

  return (
    <Box className="customize-theme-wrap">
      <Typography variant="h3">{t("eventForm:select.floor.plan")}</Typography>
      <Typography variant="body1">
        {t("eventForm:choose.customize.floor")}
      </Typography>

      {templates && (
        <>
          <RadioGroup
            aria-label={t("eventForm:label.floor.plan")}
            className="ct-types"
            name="layout"
            value={planType}
            onChange={onTypeSelect}
            row
          >
            <FormControlLabel
              className={
                planType === TEMPLATE_TYPES.REMO
                  ? styles.activeTab
                  : styles.inactiveTab
              }
              value={TEMPLATE_TYPES.REMO}
              control={<Radio color="primary" />}
              label={t("eventForm:remo.floor.plans")}
            />
            <FormControlLabel
              className={
                planType === TEMPLATE_TYPES.CUSTOM
                  ? styles.activeTab
                  : styles.inactiveTab
              }
              value={TEMPLATE_TYPES.CUSTOM}
              control={<Radio color="primary" />}
              label={t("eventForm:dynamic.custom.floor.plans", {
                key:
                  templates.privateTemplates &&
                  templates.privateTemplates.length &&
                  `(${templates.privateTemplates.length})`,
              })}
            />
          </RadioGroup>

          {planType === TEMPLATE_TYPES.CUSTOM && (
            <CustomFloorPlanUpload
              templates={templates.privateTemplates}
              onUserSelectLayout={onUserSelectLayout}
              showUploader={() => setUploaderDialogOpen(true)}
              disabled={isInPostTrialPlan(myCompanyPlan)}
              setShowSeatWarning={setShowSeatWarning}
            />
          )}
          {planType === TEMPLATE_TYPES.REMO && (
            <>
              <EventThemeSelector
                theaterData={theaterData}
                onChange={onUserSelectLayout}
                setDisplayedTheme={setDisplayedTheme}
                currentTheme={currentTheme}
                displayedTheme={displayedTheme}
                templates={templates.publicTemplates}
              />
              <Typography variant="h5" className="mar-top-20 mar-bottom-20">
                {t(`eventForm:floor.plans.${displayedTheme.toLowerCase()}`)}
              </Typography>
              <Box className={styles.filterTagsBox}>
                <FloorPlanSelectTags
                  label={t("customFloorPlan:filter.by")}
                  labelPosition="left"
                />
              </Box>
              <FloorPlanLayout
                templates={filteredPublicTemplates ?? []}
                displayedTheme={displayedTheme}
                onUserSelectLayout={onUserSelectLayout}
                setShowSeatWarning={setShowSeatWarning}
                floorCount={floorCount}
              />
            </>
          )}
        </>
      )}

      <EventFloorSettings
        eventId={eventData?.id}
        theaterData={theaterData}
        onChange={handleTheaterChange}
        isExpectedAttendanceDisabled={isExpectedAttendanceDisabled}
        handleFloorCount={handleFloorCount}
        isUnlimitedEvent={isUnlimitedEvent}
      />

      <UploaderDialog
        open={uploaderDialogOpen}
        handleClose={handleUploaderDialogClose}
      />
    </Box>
  );
};

export default CustomizeTheme;
