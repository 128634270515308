import { IUser } from "modules/app/types";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { LocateParticipantButton } from "modules/participants/components/LocateParticipantButton";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { useUpdateRecommendedUserConnections } from "modules/networkingRecommendations/hooks/useUpdateRecommendedUserConnections";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { selectUser } from "modules/auth/redux/selectors";
import { InviteRecommendedGuest } from "../InviteRecommendedGuest";
import { useStyles } from "./styles";

interface Props {
  user: IUser;
}

export const NetworkingButtons = ({ user }: Props) => {
  const { t } = useI18n("event");
  const styles = useStyles();
  const { updateConnection } = useUpdateRecommendedUserConnections(user.id);
  const { track } = useContext(TRACKING_CONTEXT);
  const currentEventId = useSelector(selectCurrentEventId);
  const currentUser = useSelector(selectUser);

  const handleRequestContactInfoClick = () => {
    if (user.id && currentEventId) {
      updateConnection({
        connected: true,
        eventId: currentEventId,
        recommendedUser: user.id,
      });
    }
  };

  const onLocate = () => {
    track(Events.RECOMMENDED_CONNECTION_LOCATE_PARTICIPANT, {
      eventId: currentEventId,
      recommendedUser: user?.email,
      currentUser: currentUser?.email,
    });
  };

  return (
    <div
      className={styles.networkingButtons}
      data-testid="networking-recommendations-buttons"
    >
      <LocateParticipantButton user={user} onLocate={onLocate} />
      <InviteRecommendedGuest userId={user.id} />
      <Tooltip title={t("request.contact.info.tooltip")}>
        <Typography
          variant="h6"
          className={styles.requestContactIfo}
          onClick={handleRequestContactInfoClick}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleRequestContactInfoClick();
          }}
        >
          {t("request.contact.info")}
        </Typography>
      </Tooltip>
    </div>
  );
};
