import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { KeyboardEvent, MouseEvent, useCallback, memo } from "react";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectNetworkingRecommendationUserCommonTopics } from "modules/networkingRecommendations/redux/selectors";
import { useUpdateRecommendedUserConnections } from "modules/networkingRecommendations/hooks/useUpdateRecommendedUserConnections";
import { RatingIcons } from "../RatingIcons";
import { useStyles } from "./styles";

export const RecommendedTooltipContent = memo(
  ({ userId }: { userId: string }) => {
    const styles = useStyles();
    const { t } = useI18n("event");
    const commonTopics = useSelector(
      selectNetworkingRecommendationUserCommonTopics(userId),
    );
    const { updateConnection } = useUpdateRecommendedUserConnections(userId);
    const currentEventId = useSelector(selectCurrentEventId);

    const handleRequestContactInfoClick = useCallback(() => {
      if (userId && currentEventId) {
        updateConnection({
          connected: true,
          eventId: currentEventId,
          recommendedUser: userId,
        });
      }
    }, [userId, currentEventId, updateConnection]);

    const handleTooltipClick = useCallback((e: MouseEvent | KeyboardEvent) => {
      e.stopPropagation();
    }, []);

    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (["Enter", " "].includes(e.key)) {
          handleTooltipClick(e);
        }
      },
      [handleTooltipClick],
    );

    return (
      <div
        onClick={handleTooltipClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        className={styles.networkingRecommendationToolTipContainer}
      >
        <Typography
          variant="body1"
          className={styles.networkingRecommendationToolTipDescription}
        >
          {t("remote.video.networking.recommendation.description")}
        </Typography>
        <ul className={styles.networkingRecommendationToolTipList}>
          {commonTopics?.map((topic) => (
            <li key={topic}>
              <Typography variant="body1">{topic}</Typography>
            </li>
          ))}
        </ul>
        <div className={styles.networkingRecommendationsRequestContactInfo}>
          <Tooltip title={t("request.contact.info.tooltip")}>
            <Typography
              variant="h5"
              className={styles.networkingRecommendationToolTipDescription}
              onClick={handleRequestContactInfoClick}
            >
              {t("request.contact.info")}
            </Typography>
          </Tooltip>
        </div>
        <RatingIcons userId={userId} />
      </div>
    );
  },
);
