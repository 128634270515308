import { sendPostRequest } from "services/apiService";
import { Rating } from "./redux/slice";

export interface AddRecommendedUserRatingPayload {
  eventId: string;
  rating: Rating | null;
  recommendedUser: string;
}

export interface UpdateRecommendedUserConnectionPayload {
  eventId: string;
  connected: boolean;
  recommendedUser: string;
}

export interface INetworkingRecommendationsConnections {
  eventId: string;
  recommendedUser: string;
  recommendedToUser: string;
  commonTopics: string[];
  about: string;
  matchPoolId: string;
  rating?: Rating | null;
  connected?: boolean;
}

type RecommendedUserResponse =
  | {
      isSuccess: boolean;
      reason?: string;
      message?: string;
      data?: INetworkingRecommendationsConnections;
    }
  | {
      isSuccess: true;
      data?: INetworkingRecommendationsConnections;
    };

export const addRecommendedUserRating = async ({
  eventId,
  ...payload
}: AddRecommendedUserRatingPayload) => {
  const resp = await sendPostRequest<
    Omit<AddRecommendedUserRatingPayload, "eventId">,
    RecommendedUserResponse
  >(`/event/${eventId}/networking-recommendations/rating`, payload);

  if (!resp.isSuccess) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};

export const updateRecommendedUserConnection = async ({
  eventId,
  ...payload
}: UpdateRecommendedUserConnectionPayload) => {
  const resp = await sendPostRequest<
    Omit<UpdateRecommendedUserConnectionPayload, "eventId">,
    RecommendedUserResponse
  >(`/event/${eventId}/networking-recommendations/connection`, payload);

  if (!resp.isSuccess) {
    throw new Error(resp?.reason ?? resp?.message);
  }

  return resp;
};
