import zIndexes from "zIndexes.module.scss";

export const styles = {
  root: {
    zIndex: zIndexes.tryCamAndMicPopoverZIndex,
  },
  content: {
    padding: 2.5,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "-2px 2px 40px #0000000d",
    overflow: "visible",
    transform: "translateY(-48px)",

    "&::before": {
      content: "''",
      position: "absolute",
      top: "auto",
      left: "50%",
      bottom: "-36px",
      borderBottom: "1rem solid transparent",
      borderRight: "1rem solid transparent",
      borderLeft: "1rem solid transparent",
      borderTop: "1.5rem solid #fff",
      zIndex: zIndexes.tryCamAndMicPopoverZIndex,
      transform: "translateX(-50%)",
    },
  },
  text: {
    marginLeft: 2.5,
    marginRight: 2.5,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    color: "present.greyBackground",
  },
};
