import { CSSProperties } from "@remo-co/ui-core/src/types";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    p: 2,
    m: 2,
    textAlign: "center",
    gap: "20px",
    borderRadius: "10px",
    border: "1px solid #EAEEF2",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#556176",
    backgroundColor: "#F9FAFB",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  image: {
    width: "133px",
    height: "120px",
    opacity: 0.5,
  },
  title: {
    mb: "10px",
    fontWeight: "bold",
  },
  reasonsList: {
    textAlign: "left",
    paddingLeft: "20px",
    margin: 0,
  },
  button: {
    height: "36px",
    marginTop: "15px",
    color: "#FFFFFF",
    borderRadius: "5px",
    fontWeight: "bold",
  },
};
