import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectTranscription = (state: RootState) => state.transcription;

export const selectShowCaptions = createSelector(
  selectTranscription,
  ({ showCaptions }) => showCaptions,
);

export const selectShowLiveCaptions = createSelector(
  selectTranscription,
  ({ showLiveCaptions }) => showLiveCaptions,
);

export const selectIsLoading = createSelector(
  selectTranscription,
  ({ isLoading }) => isLoading,
);

export const selectIsTranscriptionActive = createSelector(
  selectTranscription,
  ({ isActive }) => isActive,
);

export const selectTranscriptions = createSelector(
  selectTranscription,
  ({ transcriptions }) => transcriptions,
);
