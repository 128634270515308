import { Button } from "@remo-co/ui-core/src/components/Button";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { FiberManualRecord } from "@remo-co/ui-core/src/icons/FiberManualRecord";
import { useI18n } from "i18n";
import { deltaToDuration } from "helpers/time/operations";
import { durationTimestamp } from "helpers/time/format";
import { useSelector } from "react-redux";
import { selectCurrentEventId } from "modules/event/selectors";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { useTicker } from "modules/dateTime";
import classNames from "classnames";
import { useRecordingAsync } from "modules/recording";
import { selectPresentationSpeakersSessionIds } from "modules/audioVideo/redux/selectors";
import { RecordButton } from "../RecordButton";
import { RecordPresentationMessage } from "../RecordPresentationMessage";
import { useStyles } from "./styles";
import { RecordingInactivityNotice } from "../RecordingInactivityNotice";

const RecordingControls = (): JSX.Element => {
  const { t } = useI18n();
  const speakers = useSelector(selectPresentationSpeakersSessionIds);

  const {
    startRecording,
    stopRecording,
    isLoading,
    isRecording,
    recordingStartedDate,
  } = useRecordingAsync();

  const eventId = useSelector(selectCurrentEventId);
  const theaterId = useSelector(selectCurrentTheaterId);
  const now = useTicker(isRecording);
  const timeSinceRecordingStarted =
    isRecording && recordingStartedDate
      ? now - recordingStartedDate.getTime()
      : 0;
  const styles = useStyles();

  const handleStartRecording = () => {
    if (!theaterId || !eventId || isRecording) {
      return;
    }

    startRecording({ streamIds: speakers });
  };

  const handleStopRecording = () => {
    if (!theaterId || !eventId || !isRecording) {
      return;
    }

    stopRecording();
  };

  if (!isRecording) {
    return (
      <>
        <RecordingInactivityNotice />
        <Tooltip
          title={<RecordPresentationMessage />}
          classes={{ tooltip: styles.recordTooltip }}
          placement="top-start"
        >
          <div className={styles.recordingModuleCtn}>
            <RecordButton
              onClick={handleStartRecording}
              isProcessing={isLoading}
            />
          </div>
        </Tooltip>
      </>
    );
  }

  return (
    <div className={styles.recordingModuleCtn}>
      <div className={classNames([styles.recordButton, styles.recording])}>
        <div className={styles.static}>
          <FiberManualRecord
            className={styles.recordIcon}
            data-testid="record-icon"
          />
          <Box mr={1}>
            <Typography color="inherit">
              {`${durationTimestamp(
                deltaToDuration(timeSinceRecordingStarted),
              )}`}
            </Typography>
          </Box>
          <Button
            variant="primary"
            data-testid="stop-recording-button"
            onClick={handleStopRecording}
            loading={isLoading}
            disabled={!isRecording}
            color="red"
            size="sm"
          >
            {t("stop")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecordingControls;
