import { useContext } from "react";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Select } from "@remo-co/ui-core/src/components/Select";
import { useI18n } from "i18n";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { getMapTemplateByType } from "modules/event/template";
import { MapPreview } from "modules/eventForm";
import { getMapImageWithTheme } from "modules/theater/theater.helper";
import { useFloorNames } from "modules/floors/hooks/useFloorNames";
import { ContentMapping } from "../../types";
import { useStyles } from "./styles";

interface Props {
  mapping: ContentMapping;
  handleChange: (floorIds: string[], slot: string) => void;
  errors: string[] | null;
}

export const ContentLocation = ({ mapping, handleChange, errors }: Props) => {
  const { t } = useI18n(["eventForm", "common", "manageEvent"]);
  const styles = useStyles();

  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const { eventData } = state ?? {};
  const theater = eventData?.theaters?.[0];
  const floorNames = useFloorNames(theater?._id ?? null);
  const mapTemplate = getMapTemplateByType(theater?.template);
  const renderFloorSelectValue = (selected: unknown) => {
    const selectedOptions = selected as string[];
    const activeFloorIds = theater?.spaces ?? [];

    // fast and loose checking here, may need revision
    if (selectedOptions.length === activeFloorIds.length) {
      return t("eventForm:all.floors");
    }

    return t("eventForm:floors.number", {
      numbers: activeFloorIds
        .filter((floorId) => selectedOptions.includes(floorId))
        .map((floorId) => activeFloorIds.indexOf(floorId) + 1)
        .join(","),
    });
  };

  const handleFloorChange = (incomingIds: string[]) => {
    if (incomingIds.length === 0) {
      return;
    }

    handleChange?.(incomingIds, mapping.slot);
  };

  const handleSlotChange = (incomingSlot: string) => {
    handleChange?.(mapping.floors, incomingSlot);
  };

  if (
    !theater ||
    !theater.spaces ||
    !theater.spaces.length ||
    !mapTemplate ||
    !mapTemplate.sponsors
  ) {
    return null;
  }

  return (
    <>
      <Typography variant="body1" className={styles.label}>
        {t("eventForm:content.location")}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" className={styles.floorPrefix}>
          {t("eventForm:content.show.on.floor.prefix")}
        </Typography>
        <Select
          multiple
          size="small"
          value={mapping.floors}
          onChange={(selectedFloorIds) => handleFloorChange(selectedFloorIds)}
          options={theater.spaces.map((floorId, index) => ({
            label: floorNames[floorId]
              ? floorNames[floorId]
              : t("manageEvent:floor.number", {
                  number: index + 1,
                }),
            value: floorId,
          }))}
          SelectDisplayProps={{
            className: styles.select,
          }}
          renderValue={renderFloorSelectValue}
          inputTestId="floor-select"
          error={Boolean(errors)}
          disableOptionMaxWidth
        />
        <Typography variant="body1" className={styles.slotPrefix}>
          {t("eventForm:content.slot.in.prefix")}
        </Typography>
        <Select
          size="small"
          value={mapping.slot}
          onChange={(selectedSlot) => handleSlotChange(selectedSlot)}
          options={mapTemplate.sponsors.map((_, index) => ({
            label: t("eventForm:slot.number", {
              key: index + 1,
            }),
            value: `${index}`,
          }))}
          SelectDisplayProps={{
            className: styles.select,
          }}
          inputTestId="slot-select"
          error={Boolean(errors)}
        />
      </Box>
      {errors?.map((error) => (
        <Typography variant="caption" className={styles.error}>
          {error}
        </Typography>
      ))}
      {mapTemplate && (
        <MapPreview
          theme={theater.theme}
          mapImage={getMapImageWithTheme(mapTemplate, theater.theme)}
          highlight={parseInt(mapping.slot, 10)}
          selectedTemplate={mapTemplate}
          eventId={eventData.id}
          className={styles.mapPreview}
        />
      )}
    </>
  );
};
