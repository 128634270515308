import { getInviteUrl } from "helpers/urlHelper";
import copy from "copy-to-clipboard";
import {
  selectCurrentEventCode,
  selectCurrentEventId,
} from "modules/event/selectors";
import { selectCurrentSpaceId } from "modules/space/redux/selectors";
import { useSelector } from "react-redux";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useContext } from "react";
import { Events, TRACKING_CONTEXT } from "modules/tracking";

export const useCopyTableLink = () => {
  const eventCode = useSelector(selectCurrentEventCode);
  const floorId = useSelector(selectCurrentSpaceId);
  const eventId = useSelector(selectCurrentEventId);
  const { t } = useI18n();
  const { addSuccessNotification, addErrorNotification } =
    useNotificationActions();

  const { track } = useContext(TRACKING_CONTEXT);

  const copyTableLink = (tableId: string) => {
    if (!eventCode || !floorId) {
      addErrorNotification({
        message: t("unexpected.error"),
        position: "tc",
        autoDismiss: 5,
      });
      return;
    }
    const url = new URL(getInviteUrl({ code: eventCode }));
    url.searchParams.set("table", tableId);
    url.searchParams.set("floor", floorId);
    copy(url.toString());

    addSuccessNotification({
      message: t("event:copied.link"),
      position: "tc",
      autoDismiss: 5,
    });

    track(Events.SHARE_TABLE_LINK, {
      event: eventId ?? "", // remove after complete migration to datagol
      eventId: eventId ?? "",
    });
  };

  return { copyTableLink };
};
