import { RemoLogo } from "@remo-co/ui-core/src/components/RemoLogo";
import { useI18n } from "i18n";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { styles } from "./styles";

function refresh() {
  window.location.reload();
}

export const SingleAppInstanceMessage = () => {
  const { t } = useI18n();

  return (
    <Box sx={styles.container}>
      <Box data-testid="secondary-instance-message" sx={styles.root}>
        <Box sx={styles.content}>
          <RemoLogo width={225} />
          <Box sx={styles.text1}>
            <Typography>{t("using.remo.app")}</Typography>
            <Typography>{t("conference.multiple.tabs.device")}</Typography>
          </Box>
          <Box sx={styles.text2}>
            <Typography>{t("now.remo.works")}</Typography>
            <Typography>{t("reliable.single.tab.device")}</Typography>
          </Box>
          <Box sx={styles.text3}>
            <Typography>{t("disable.remo.instance")}</Typography>
            <Typography>{t("refresh.page")}</Typography>
          </Box>
          <Button
            onClick={refresh}
            sx={styles.button}
            variant="primary"
            color="blue"
          >
            {t("disable.other.remo.app")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
