import { useSelector } from "react-redux";
import { Dialog } from "@remo-co/ui-core/src/components/Dialog";
import { DialogContent } from "@remo-co/ui-core/src/components/DialogContent";
import { IconButtonWithTooltip } from "@remo-co/ui-core/src/components/IconButtonWithTooltip";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useAppDispatch } from "store/hooks";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { hideInactivityNotice } from "../../redux/actions";
import { selectIsInactivityNoticeVisible } from "../../redux/selectors";
import { styles } from "./styles";

export const RecordingInactivityNotice = () => {
  const dispatch = useAppDispatch();
  const { t } = useI18n(["event"]);
  const isInactivityNoticeVisible = useSelector(
    selectIsInactivityNoticeVisible,
  );

  if (!isInactivityNoticeVisible) {
    return null;
  }

  const handlePopupClose = () => {
    dispatch(hideInactivityNotice());
  };

  return (
    <Dialog
      data-testid="inactivity-notice"
      maxWidth="xs"
      open
      onClose={handlePopupClose}
      PaperProps={{
        sx: styles.paper,
      }}
    >
      <DialogContent>
        <Box sx={styles.heading}>
          <Typography variant="h4" sx={styles.title}>
            {t("event:inactivity.notice.title")}
          </Typography>
          <IconButtonWithTooltip
            title={t("common:button.close")}
            aria-label={t("modal.window.aria.label")}
            id="inactivity-notice-close-button"
            data-testid="inactivity-notice-close-button"
            onClick={handlePopupClose}
            size="small"
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButtonWithTooltip>
        </Box>
        <Typography variant="body1" sx={styles.promptContent}>
          {t("event:inactivity.notice.description")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
