import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { useI18n } from "i18n";
import { useEffect, useState, memo, useCallback } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useStyles } from "./styles";
import { RecommendedTooltipContent } from "./RecommendedTooltipContent";

interface Props {
  userId: string;
  toolTipOpen?: boolean;
}

export const RecommendedTooltip = memo(({ userId, toolTipOpen }: Props) => {
  const { t } = useI18n("event");
  const styles = useStyles();

  const [isTooltipVisible, setIsTooltipVisible] = useState(toolTipOpen);

  useEffect(() => {
    setIsTooltipVisible(toolTipOpen);
  }, [toolTipOpen]);

  const handleMouseEnter = useCallback(() => {
    if (!toolTipOpen && !isTooltipVisible) {
      setIsTooltipVisible(true);
    }
  }, [toolTipOpen, isTooltipVisible]);

  const handleMouseLeave = useCallback(() => {
    if (!toolTipOpen && isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  }, [toolTipOpen, isTooltipVisible]);

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "flip",
            enabled: true,
            options: {
              fallbackPlacements: ["top", "bottom", "right"],
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: { boundariesElement: "viewport" },
          },
        ],
      }}
      title={<RecommendedTooltipContent userId={userId} />}
      arrow
      disableHoverListener={false}
      leaveDelay={150}
      open={isTooltipVisible}
      classes={{ tooltip: styles.tooltip }}
      data-testid="remote-tile-networking-recommendations-tooltip"
    >
      <div
        className={styles.recommendedTooltipTitle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography variant="body1" className={styles.tooltipText}>
          {t("recommended")}
        </Typography>
      </div>
    </Tooltip>
  );
});
