import { useSelector } from "react-redux";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { useI18n } from "i18n";
import { BulbIcon } from "@remo-co/ui-core/src/icons/Bulb";
import { memo } from "react";
import {
  selectRecommendedUsersWithNoRating,
  selectRecommendedUsersWithRating,
} from "./redux/selectors";
import useStyles from "./styles";
import { NetworkingParticipantDetails } from "./components/NetworkingParticipantDetails";
import { NoConnectionsContainer } from "./components/NoConnectionsContainer";

export const SectionTitle = memo(({ text }: { text: string }) => {
  const styles = useStyles();

  return (
    <div className={styles.text}>
      <Typography variant="h5">{text}</Typography>
    </div>
  );
});

export const NetworkingRecommendationsContainer = () => {
  const { t } = useI18n("event");
  const styles = useStyles();

  const networkingRecommendationsNotRated = useSelector(
    selectRecommendedUsersWithNoRating,
  );
  const networkingRecommendationsWithRating = useSelector(
    selectRecommendedUsersWithRating,
  );

  // No recommendations case
  if (
    !networkingRecommendationsNotRated.length &&
    !networkingRecommendationsWithRating.length
  ) {
    return (
      <div className={styles.container}>
        <NoConnectionsContainer />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <SectionTitle text={t("we.think.you.would.connect.well.with")} />

      <div className={styles.tipContainer}>
        <BulbIcon data-testid="networking-recommendations-tip-icon" />
        <Typography variant="body1">
          {t("networking.recommendations.tip")}
        </Typography>
      </div>

      {networkingRecommendationsNotRated.map((user) => (
        <NetworkingParticipantDetails user={user} />
      ))}

      {networkingRecommendationsWithRating.length > 0 && (
        <>
          <SectionTitle
            text={t("your.rated.connections", {
              key: networkingRecommendationsWithRating.length,
            })}
          />
          {networkingRecommendationsWithRating.map((user) => (
            <NetworkingParticipantDetails user={user} />
          ))}
        </>
      )}
    </div>
  );
};
