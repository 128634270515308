import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  switchBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.625),
    marginTop: theme.spacing(2.5),
  },
  tooltip: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.white,
    width: theme.spacing(28.5),
    gap: 0,
    opacity: 1,
    textAlign: "left",
    "& a": {
      color: theme.palette.white,
    },
    "& .MuiTooltip-arrow": {
      color: theme.palette.blue.dark,
    },
  },
  tooltipText: {
    color: theme.palette.white,
  },
  tooltipLinkText: {
    color: theme.palette.white,
    textDecoration: "underline",
  },
  checkbox: {
    position: "relative",
    width: "42px",
    height: "26px",
    appearance: "none",
    background: "#ccc",
    borderRadius: "15px",
    outline: "none",
    cursor: "pointer",
    transition: "background 0.3s ease-in-out",

    "&:checked": {
      background: theme.palette.blue.main,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      width: "20px",
      height: "20px",
      background: "#fff",
      borderRadius: "50%",
      top: "3px",
      left: "3px",
      transition: "transform 0.3s ease-in-out",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    },
    "&:checked::before": {
      transform: "translateX(16px)",
    },
    "&:disabled": {
      cursor: "not-allowed",
      background: "#e0e0e0",
    },
    "&:disabled::before": {
      background: "#bdbdbd",
    },
  },
}));
