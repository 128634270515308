export const styles = {
  paper: {
    borderRadius: "10px",
    padding: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  heading: {
    paddingBottom: 1,
  },
  title: {
    fontWeight: 700,
  },
  closeButton: {
    position: "absolute",
    top: 1,
    right: 1,
  },
  promptContent: {
    paddingBottom: 1,
  },
};
