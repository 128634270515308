import React from "react";
import { SingleAppInstanceMessage } from "./components/SingleAppInstanceMessage";
import { useIsMasterAppInstance } from "./useIsMasterAppInstance";

type EnsureSingleAppInstanceProps = {
  children: React.ReactNode;
};

const EnsureSingleAppInstance = ({
  children,
}: EnsureSingleAppInstanceProps) => {
  const isMasterAppInstanceLocal = useIsMasterAppInstance();

  if (isMasterAppInstanceLocal) {
    return <div data-testid="single-app-instance-container">{children}</div>;
  }

  return <SingleAppInstanceMessage />;
};

export const ensureSingleAppInstance =
  (Component: React.FunctionComponent) => (props: JSX.IntrinsicAttributes) =>
    (
      <EnsureSingleAppInstance>
        <Component {...props} />
      </EnsureSingleAppInstance>
    );

export { isMasterAppInstance } from "./useIsMasterAppInstance";

export default EnsureSingleAppInstance;
