export const styles = {
  roomJoinButton: {
    width: "250px",
    marginRight: "15px",
    maxWidth: "100% !important",
  },
  roomJoinFab: {
    backgroundColor: "#fce1db !important",
    borderRadius: "10px !important",
    color: "var(--Red) !important",
    width: "48px !important",
    marginLeft: 0,
  },
};
