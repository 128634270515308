import React from "react";
import { useSelector } from "react-redux";
import { selectJoiningRoomId } from "store/rooms/selectors";
import { Fab } from "@remo-co/ui-core/src/components/Fab";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Close as CloseIcon } from "@remo-co/ui-core/src/icons/Close";
import { useI18n } from "i18n";
import { useJoinTable } from "modules/joinTable";
import { useAppDispatch } from "store/hooks";
import {
  JoinRoomStatus,
  setJoiningRoomId,
  updateRoomStatus,
} from "store/rooms";
import { styles } from "./styles";

export const RoomJoinButton = () => {
  const dispatch = useAppDispatch();
  const { switchTable } = useJoinTable();
  const { t } = useI18n(["template"]);
  const joiningRoomId = useSelector(selectJoiningRoomId);

  const handleJoinClick = React.useCallback(() => {
    if (!joiningRoomId) {
      return;
    }

    switchTable({ tableId: joiningRoomId });
    dispatch(updateRoomStatus(JoinRoomStatus.DONE));
    dispatch(setJoiningRoomId(null));

    // reset id to prevent race condition which briefly renders previous joining room styles
  }, [dispatch, joiningRoomId, switchTable]);

  const handleCancelClick = React.useCallback(() => {
    dispatch(updateRoomStatus(JoinRoomStatus.DONE));
    dispatch(setJoiningRoomId(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        variant="primary"
        color="blue"
        onClick={handleJoinClick}
        sx={styles.roomJoinButton}
      >
        {t("template:join.table")}
      </Button>
      <Fab
        size="medium"
        disableRipple
        onClick={handleCancelClick}
        sx={styles.roomJoinFab}
      >
        <CloseIcon />
      </Fab>
    </>
  );
};
